import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';

const IndexPage = props => {
  const {
    markdownRemark: {
      frontmatter: {
        title,
        subtitle,
        buttonText,
        section1,
        section2,
        section3,
      },
    },
  } = useStaticQuery(graphql`
    query HomepageQuery {
      markdownRemark(frontmatter: { pageName: { eq: "homeindex" } }) {
        frontmatter {
          title
          subtitle
          buttonText
          section1 {
            description
            linkText
            title
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          section2 {
            description
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            linkText
          }
          section3 {
            description
            linkText
            title
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);
  return (
    <Layout fullMenu>
      <section id="banner">
        <div className="inner">
          <div className="logo">
            {/* <span className="icon fa-diamond"></span> */}
          </div>
          <h2>{title}</h2>
          <p>{subtitle}</p>
          <ul className="actions">
            <li>
              <a href="#contact" className="button primary">
                {buttonText}
              </a>
            </li>
          </ul>
        </div>
      </section>

      <section id="wrapper">
        <section id="one" className="wrapper spotlight style1">
          <div className="inner">
            <Link to="/team" className="image">
              <Img fluid={section1.image.childImageSharp.fluid} alt="" />
            </Link>
            <div className="content">
              <h2 className="major">{section1.title}</h2>
              <p>{section1.description}</p>
              <Link to="/team" className="special">
                {section1.linkText}
              </Link>
            </div>
          </div>
        </section>

        <section id="two" className="wrapper alt spotlight style2">
          <div className="inner">
            <Link to="/background" className="image">
              <Img fluid={section2.image.childImageSharp.fluid} alt="" />
            </Link>
            <div className="content">
              <h2 className="major">{section2.title}</h2>
              <p>{section2.description}</p>
              <Link to="/background" className="special">
                {section2.linkText}
              </Link>
            </div>
          </div>
        </section>

        <section id="three" className="wrapper spotlight style3">
          <div className="inner">
            <Link to="/gallery" className="image">
              <Img fluid={section3.image.childImageSharp.fluid} alt="" />
            </Link>
            <div className="content">
              <h2 className="major">{section3.title}</h2>
              <p>{section3.description}</p>
              <Link to="/gallery" className="special">
                {section3.linkText}
              </Link>
            </div>
          </div>
        </section>

        {/* <section id="four" className="wrapper alt style1">
        <div className="inner">
          <h2 className="major">Vitae phasellus</h2>
          <p>
            Cras mattis ante fermentum, malesuada neque vitae, eleifend erat.
            Phasellus non pulvinar erat. Fusce tincidunt, nisl eget mattis
            egestas, purus ipsum consequat orci, sit amet lobortis lorem lacus
            in tellus. Sed ac elementum arcu. Quisque placerat auctor laoreet.
          </p>
          <section className="features">
            <article>
              <a href="/#" className="image">
                <img src={kidmoney} alt="" />
              </a>
              <h3 className="major">Sed feugiat lorem</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing vehicula id
                nulla dignissim dapibus ultrices.
              </p>
              <a href="/#" className="special">
                Learn more
              </a>
            </article>
            <article>
              <a href="/#" className="image">
                <img src={chaVan} alt="" />
              </a>
              <h3 className="major">Nisl placerat</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing vehicula id
                nulla dignissim dapibus ultrices.
              </p>
              <a href="/#" className="special">
                Learn more
              </a>
            </article>
            <article>
              <a href="/#" className="image">
                <img src={redPurpleBow} alt="" />
              </a>
              <h3 className="major">Ante fermentum</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing vehicula id
                nulla dignissim dapibus ultrices.
              </p>
              <a href="/#" className="special">
                Learn more
              </a>
            </article>
            <article>
              <a href="/#" className="image">
                <img src={donpicture} alt="" />
              </a>
              <h3 className="major">Fusce consequat</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing vehicula id
                nulla dignissim dapibus ultrices.
              </p>
              <a href="/#" className="special">
                Learn more
              </a>
            </article>
          </section>
          <ul className="actions">
            <li>
              <a href="/#" className="button">
                Browse All
              </a>
            </li>
          </ul>
        </div>
      </section> */}
      </section>
    </Layout>
  );
};

export default IndexPage;
